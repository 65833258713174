import { RefObject, useEffect, useRef, useState } from 'react';

import { Swiper } from 'swiper';
import {
  Autoplay as SwiperAutolay,
  EffectFade as SwiperEffectFade,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination,
} from 'swiper/modules';

import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  HeroBlockFragment,
  SliderBlockFragment,
} from '@hultafors/hellberg/types';

import { HeroBlock } from '../HeroBlock/HeroBlock';

import ChevronSvg from './chevron.svg';
import {
  ArrowBtn,
  ArrowNavigator,
  DotBtn,
  DotNavigator,
  DotNavigatorInner,
  SliderBlockStyled,
} from './SliderBlock.styled';

require('swiper/css');
require('swiper/css/navigation');

export const SliderBlock: React.FC<SliderBlockFragment> = ({
  arrows,
  navigationDots,
  delay: initialDelay,
  slides,
  slideAnimation,
  title,
}) => {
  const { shared } = useGlobal();

  const swiper = useRef<Swiper | null>(null);
  const swiperElement = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // Check if Slide Anmiation is checked
  // If not, include Fade Module
  const SwiperModules = slideAnimation
    ? [SwiperAutolay, SwiperNavigation, SwiperPagination]
    : [SwiperAutolay, SwiperEffectFade, SwiperNavigation, SwiperPagination];

  const shouldUseSlideAnimation = slideAnimation ? 'slide' : 'fade';

  function initializeSwiper(element: RefObject<HTMLDivElement>) {
    if (element.current && !swiper.current)
      swiper.current = new Swiper(element.current, {
        allowTouchMove: true,
        autoplay: {
          delay: initialDelay * 1000,
          disableOnInteraction: false,
        },

        direction: 'horizontal',
        effect: shouldUseSlideAnimation,
        loop: true,

        modules: SwiperModules,
        on: {
          realIndexChange: (swiper) => {
            setCurrentIndex(swiper.realIndex);
          },
        },
        pagination: {
          clickable: true,
          el: paginationRef.current,
          type: 'bullets',
        },
        speed: 800,
      });
  }

  useEffect(() => {
    initializeSwiper(swiperElement);
  }, []);

  function slidesMapper(hero: HeroBlockFragment, index: number) {
    return (
      <div className="swiper-slide" key={`Slider-hero-${index}`}>
        <HeroBlock {...hero} priority={index === 0} isInSlider />
      </div>
    );
  }

  function paginationDotsMapper(hero: HeroBlockFragment, index: number) {
    function onClick() {
      swiper.current?.slideToLoop(index);
    }
    return (
      <DotBtn
        key={`Pagination-button-${index}`}
        $active={currentIndex === index}
        onClick={onClick}
        aria-label={`${shared?.sliderNavigationLabel} ${index + 1}`}
      >
        <span />
      </DotBtn>
    );
  }

  function slidePrev() {
    swiper.current?.slidePrev();
  }
  function slideNext() {
    swiper.current?.slideNext();
  }

  return (
    <SliderBlockStyled>
      <div className="swiper" ref={swiperElement}>
        <div className="swiper-wrapper">{slides.map(slidesMapper)}</div>
      </div>
      {arrows && (
        <ArrowNavigator>
          <ArrowBtn
            aria-label={shared?.previousLabel || ''}
            ref={prevRef}
            onClick={slidePrev}
          >
            <ChevronSvg />
          </ArrowBtn>
          <ArrowBtn
            aria-label={shared?.nextLabel || ''}
            ref={nextRef}
            onClick={slideNext}
          >
            <ChevronSvg />
          </ArrowBtn>
        </ArrowNavigator>
      )}
      {navigationDots && (
        <DotNavigator>
          <DotNavigatorInner>
            {slides.map(paginationDotsMapper)}
          </DotNavigatorInner>
        </DotNavigator>
      )}
    </SliderBlockStyled>
  );
};
